import { SVGProps } from "react";

export default function CalendarIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.2 3.19981H11.72V1.50781C11.72 1.3752 11.6673 1.24803 11.5736 1.15426C11.4798 1.06049 11.3526 1.00781 11.22 1.00781C11.0874 1.00781 10.9602 1.06049 10.8664 1.15426C10.7727 1.24803 10.72 1.3752 10.72 1.50781V3.19981H5.28V1.50781C5.28 1.3752 5.22732 1.24803 5.13355 1.15426C5.03979 1.06049 4.91261 1.00781 4.78 1.00781C4.64739 1.00781 4.52021 1.06049 4.42645 1.15426C4.33268 1.24803 4.28 1.3752 4.28 1.50781V3.19981H1.8C1.58783 3.19981 1.38434 3.2841 1.23431 3.43413C1.08429 3.58416 1 3.78764 1 3.99981V14.1998C1 14.412 1.08429 14.6155 1.23431 14.7655C1.38434 14.9155 1.58783 14.9998 1.8 14.9998H14.2C14.4122 14.9998 14.6157 14.9155 14.7657 14.7655C14.9157 14.6155 15 14.412 15 14.1998V3.99981C15 3.78764 14.9157 3.58416 14.7657 3.43413C14.6157 3.2841 14.4122 3.19981 14.2 3.19981ZM14 13.9998H2V7.27981H14V13.9998ZM2 6.26381V4.21181H4.28V4.91581C4.28 5.04842 4.33268 5.1756 4.42645 5.26937C4.52021 5.36313 4.64739 5.41581 4.78 5.41581C4.91261 5.41581 5.03979 5.36313 5.13355 5.26937C5.22732 5.1756 5.28 5.04842 5.28 4.91581V4.21181H10.72V4.91581C10.72 5.04842 10.7727 5.1756 10.8664 5.26937C10.9602 5.36313 11.0874 5.41581 11.22 5.41581C11.3526 5.41581 11.4798 5.36313 11.5736 5.26937C11.6673 5.1756 11.72 5.04842 11.72 4.91581V4.21181H14V6.26381H2Z"
        fill="currentColor"
      />
    </svg>
  );
}
