import { SVGProps } from "react";

export default function SuccessIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="171"
      height="80"
      viewBox="0 0 171 80"
      fill="none"
      {...props}
    >
      <g clipPath="url(#clip0_4800_41301)">
        <path
          d="M99.3174 65.3425V57.4998C99.3174 56.0655 98.151 54.9028 96.7122 54.9028H74.8285C73.3896 54.9028 72.2233 56.0655 72.2233 57.4998V65.3425C72.2233 66.7767 73.3896 67.9394 74.8285 67.9394H96.7122C98.151 67.9394 99.3174 66.7767 99.3174 65.3425Z"
          fill="#CBDFFD"
          stroke="#232942"
          strokeLinejoin="round"
        />
        <path
          d="M116.633 5.63037H54.9069C37.9905 5.63037 24.2871 19.3075 24.2871 36.1529C24.2871 53.0156 38.0078 66.6754 54.9069 66.6754H69.9303C72.3444 66.6754 74.6544 67.6276 76.3565 69.3242L83.9289 76.8726C84.9363 77.8768 86.5862 77.8768 87.6109 76.8726L95.1834 69.3242C96.8855 67.6276 99.1954 66.6754 101.61 66.6754H116.633C133.549 66.6754 147.253 52.9982 147.253 36.1529C147.253 19.2902 133.532 5.63037 116.633 5.63037Z"
          fill="#4CBF8C"
          stroke="#232942"
          strokeLinejoin="round"
        />
        <path
          d="M81.2018 56.6338C80.264 56.6338 79.3087 56.3222 78.5272 55.6816L64.8585 44.411C63.0696 42.9394 62.8265 40.3079 64.3027 38.5246C65.779 36.7414 68.419 36.499 70.2079 37.9706L80.6461 46.5751L103.52 18.9958C104.996 17.2125 107.636 16.9702 109.425 18.4418C111.214 19.9133 111.457 22.5449 109.981 24.3281L84.4323 55.1276C83.5986 56.1318 82.4002 56.6511 81.2018 56.6511V56.6338Z"
          fill="white"
          stroke="#232942"
          strokeLinejoin="round"
        />
        <path
          d="M10.4278 18.0264C9.45518 18.0264 8.65625 17.23 8.65625 16.2605V14.408C8.65625 13.4385 9.45518 12.6421 10.4278 12.6421C11.4004 12.6421 12.1993 13.4385 12.1993 14.408V16.2605C12.1993 17.23 11.4004 18.0264 10.4278 18.0264Z"
          fill="white"
          stroke="#232942"
          strokeLinejoin="round"
        />
        <path
          d="M10.4278 29.5396C9.45518 29.5396 8.65625 28.7432 8.65625 27.7737V25.9212C8.65625 24.9517 9.45518 24.1553 10.4278 24.1553C11.4004 24.1553 12.1993 24.9517 12.1993 25.9212V27.7737C12.1993 28.7432 11.4004 29.5396 10.4278 29.5396Z"
          fill="white"
          stroke="#232942"
          strokeLinejoin="round"
        />
        <path
          d="M17.1319 22.8565H15.2735C14.3009 22.8565 13.502 22.0601 13.502 21.0906C13.502 20.1211 14.3009 19.3247 15.2735 19.3247H17.1319C18.1045 19.3247 18.9034 20.1211 18.9034 21.0906C18.9034 22.0601 18.1045 22.8565 17.1319 22.8565Z"
          fill="white"
          stroke="#232942"
          strokeLinejoin="round"
        />
        <path
          d="M5.58207 22.8565H3.72369C2.75108 22.8565 1.95215 22.0601 1.95215 21.0906C1.95215 20.1211 2.75108 19.3247 3.72369 19.3247H5.58207C6.55468 19.3247 7.35361 20.1211 7.35361 21.0906C7.35361 22.0601 6.55468 22.8565 5.58207 22.8565Z"
          fill="white"
          stroke="#232942"
          strokeLinejoin="round"
        />
        <path
          d="M2.40765 12.8C3.73736 12.8 4.81531 11.7255 4.81531 10.4C4.81531 9.07452 3.73736 8 2.40765 8C1.07794 8 0 9.07452 0 10.4C0 11.7255 1.07794 12.8 2.40765 12.8Z"
          fill="#232942"
        />
        <path
          d="M17.6565 8.0001C19.4294 8.0001 20.8667 6.56741 20.8667 4.8001C20.8667 3.03279 19.4294 1.6001 17.6565 1.6001C15.8835 1.6001 14.4463 3.03279 14.4463 4.8001C14.4463 6.56741 15.8835 8.0001 17.6565 8.0001Z"
          fill="white"
          stroke="#232942"
          strokeMiterlimit="10"
        />
        <path
          d="M156.076 59.8716C155.104 59.8716 154.305 59.0752 154.305 58.1057V56.2532C154.305 55.2837 155.104 54.4873 156.076 54.4873C157.049 54.4873 157.848 55.2837 157.848 56.2532V58.1057C157.848 59.0752 157.049 59.8716 156.076 59.8716Z"
          fill="white"
          stroke="#232942"
          strokeLinejoin="round"
        />
        <path
          d="M156.076 71.3848C155.104 71.3848 154.305 70.5884 154.305 69.6189V67.7664C154.305 66.7969 155.104 66.0005 156.076 66.0005C157.049 66.0005 157.848 66.7969 157.848 67.7664V69.6189C157.848 70.5884 157.049 71.3848 156.076 71.3848Z"
          fill="white"
          stroke="#232942"
          strokeLinejoin="round"
        />
        <path
          d="M162.798 64.7017H160.94C159.967 64.7017 159.168 63.9053 159.168 62.9358C159.168 61.9663 159.967 61.1699 160.94 61.1699H162.798C163.77 61.1699 164.569 61.9663 164.569 62.9358C164.569 63.9053 163.77 64.7017 162.798 64.7017Z"
          fill="white"
          stroke="#232942"
          strokeLinejoin="round"
        />
        <path
          d="M151.231 64.7017H149.372C148.4 64.7017 147.601 63.9053 147.601 62.9358C147.601 61.9663 148.4 61.1699 149.372 61.1699H151.231C152.203 61.1699 153.002 61.9663 153.002 62.9358C153.002 63.9053 152.203 64.7017 151.231 64.7017Z"
          fill="white"
          stroke="#232942"
          strokeLinejoin="round"
        />
        <path
          d="M168.536 52.8001C169.422 52.8001 170.141 52.0838 170.141 51.2001C170.141 50.3164 169.422 49.6001 168.536 49.6001C167.649 49.6001 166.931 50.3164 166.931 51.2001C166.931 52.0838 167.649 52.8001 168.536 52.8001Z"
          fill="#232942"
        />
        <path
          d="M158.103 48C160.319 48 162.115 46.2091 162.115 44C162.115 41.7909 160.319 40 158.103 40C155.886 40 154.09 41.7909 154.09 44C154.09 46.2091 155.886 48 158.103 48Z"
          fill="white"
          stroke="#232942"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_4800_41301">
          <rect width="170.141" height="80" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
