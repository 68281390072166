import { SVGProps } from "react";

export default function ApprovedIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="24" height="24" rx="12" fill="#4CBF8C" />
      <path
        d="M9.88169 16.6398C9.77666 16.6406 9.67257 16.62 9.57575 16.5793C9.47893 16.5386 9.3914 16.4786 9.31849 16.403L5.86889 12.9598C5.71867 12.8096 5.63428 12.6059 5.63428 12.3934C5.63428 12.181 5.71867 11.9772 5.86889 11.827C6.01911 11.6768 6.22285 11.5924 6.43529 11.5924C6.64773 11.5924 6.85147 11.6768 7.00169 11.827L9.88169 14.707L16.9985 7.59661C17.0708 7.51528 17.1589 7.44956 17.2575 7.40346C17.3561 7.35736 17.463 7.33185 17.5718 7.3285C17.6805 7.32516 17.7889 7.34403 17.8901 7.38398C17.9913 7.42393 18.0833 7.48411 18.1605 7.56084C18.2376 7.63757 18.2983 7.72923 18.3389 7.83023C18.3794 7.93122 18.3989 8.03942 18.3961 8.14821C18.3934 8.257 18.3685 8.36408 18.323 8.46292C18.2774 8.56175 18.2122 8.65025 18.1313 8.72301L10.4513 16.403C10.3776 16.4794 10.2891 16.5398 10.1911 16.5806C10.0931 16.6213 9.98779 16.6415 9.88169 16.6398Z"
        fill="white"
      />
    </svg>
  );
}
