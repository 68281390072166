import { SVGProps } from "react";

export default function StatisticsIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.4761 13.9722H13.3801V1.83223C13.3801 1.39223 13.0201 1.03223 12.5801 1.03223H9.78011C9.34011 1.03223 8.98011 1.39223 8.98011 1.83223V13.9762H7.01611V6.36823C7.01611 5.92823 6.65611 5.56823 6.21611 5.56823H3.41611C2.97611 5.56823 2.61611 5.92823 2.61611 6.36823V13.9762H1.52411C1.24411 13.9762 1.01611 14.2042 1.01611 14.4842C1.01611 14.7642 1.24411 14.9922 1.52411 14.9922H14.4761C14.7561 14.9922 14.9841 14.7642 14.9841 14.4842C14.9841 14.2042 14.7561 13.9762 14.4761 13.9762V13.9722ZM9.98011 2.03223H12.3801V13.9682H9.98011V2.03223ZM3.61611 6.56823H6.01611V13.9682H3.61611V6.56823Z"
        fill="currentColor"
      />
    </svg>
  );
}
