import { SVGProps } from "react";

export default function CouponIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M17.11 16.1849H2.89C1.995 16.1849 1.265 15.4549 1.265 14.5599V5.43994C1.265 4.54494 1.995 3.81494 2.89 3.81494H17.11C18.005 3.81494 18.735 4.54494 18.735 5.43994V7.23994C18.735 7.58494 18.455 7.86494 18.11 7.86494C16.93 7.86494 15.975 8.82494 15.975 9.99994C15.975 11.1749 16.935 12.1349 18.11 12.1349C18.455 12.1349 18.735 12.4149 18.735 12.7599V14.5599C18.735 15.4549 18.005 16.1849 17.11 16.1849ZM2.89 5.06494C2.68 5.06494 2.515 5.23494 2.515 5.43994V14.5599C2.515 14.7699 2.685 14.9349 2.89 14.9349H17.11C17.32 14.9349 17.485 14.7649 17.485 14.5599V13.3299C15.915 13.0349 14.725 11.6549 14.725 9.99994C14.725 8.34494 15.915 6.96494 17.485 6.66994V5.43994C17.485 5.22994 17.315 5.06494 17.11 5.06494H2.89Z"
        fill="#005fc5"
      />
      <path
        d="M4.94 13.615C4.78 13.615 4.62 13.555 4.5 13.43C4.255 13.185 4.255 12.79 4.5 12.545L10.59 6.45499C10.835 6.20999 11.23 6.20999 11.475 6.45499C11.72 6.69999 11.72 7.09499 11.475 7.33999L5.385 13.43C5.265 13.55 5.105 13.615 4.945 13.615H4.94Z"
        fill="#005fc5"
      />
      <path
        d="M5.635 9.37494C4.655 9.37494 3.855 8.57494 3.855 7.59494C3.855 6.61494 4.655 5.81494 5.635 5.81494C6.615 5.81494 7.415 6.61494 7.415 7.59494C7.415 8.57494 6.615 9.37494 5.635 9.37494ZM5.635 7.06494C5.345 7.06494 5.105 7.29994 5.105 7.59494C5.105 7.88994 5.34 8.12494 5.635 8.12494C5.93 8.12494 6.165 7.88994 6.165 7.59494C6.165 7.29994 5.93 7.06494 5.635 7.06494Z"
        fill="#005fc5"
      />
      <path
        d="M10.33 14.07C9.35 14.07 8.55 13.27 8.55 12.29C8.55 11.31 9.35 10.51 10.33 10.51C11.31 10.51 12.11 11.31 12.11 12.29C12.11 13.27 11.31 14.07 10.33 14.07ZM10.33 11.765C10.04 11.765 9.8 12 9.8 12.295C9.8 12.59 10.035 12.825 10.33 12.825C10.625 12.825 10.86 12.59 10.86 12.295C10.86 12 10.625 11.765 10.33 11.765Z"
        fill="#005fc5"
      />
    </svg>
  );
}
