import { SVGProps } from "react";

export default function CommonExpenseIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={40}
      height={40}
      fill="none"
      viewBox="0 0 40 40"
      {...props}
    >
      <path
        fill="#232942"
        d="m33.4 10.59-.51-.51s-.02-.03-.03-.04l-6.53-6.53s-.03-.02-.04-.03l-.51-.51c-.23-.23-.55-.37-.88-.37H9.49c-1.79 0-3.25 1.46-3.25 3.25v28.3c0 1.79 1.46 3.25 3.25 3.25h21.02c1.79 0 3.25-1.46 3.25-3.25V11.47c0-.33-.13-.65-.37-.88h.01Zm-7.8-4.27 4.45 4.45h-2.7c-.96 0-1.75-.79-1.75-1.75v-2.7Zm4.92 28.58H9.49c-.41 0-.75-.34-.75-.75V5.85c0-.41.34-.75.75-.75H23.1v3.92a4.26 4.26 0 0 0 4.25 4.25h3.92v20.88c0 .41-.34.75-.75.75Z"
      />
      <path
        fill="#232942"
        d="M27.94 15.45H12.06c-.69 0-1.25.56-1.25 1.25v14.61c0 .69.56 1.25 1.25 1.25h15.87c.69 0 1.25-.56 1.25-1.25V16.7c0-.69-.56-1.25-1.25-1.25h.01Zm-1.25 7.31h-7.36v-4.81h7.36v4.81Zm-9.86-4.81v4.81h-3.51v-4.81h3.51Zm-3.51 7.31h3.51v4.81h-3.51v-4.81Zm6.01 4.81v-4.81h7.36v4.81h-7.36Z"
      />
    </svg>
  );
}
