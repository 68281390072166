import { SVGProps } from "react";

export default function InfoIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 2C9.18669 2 10.3467 2.35189 11.3334 3.01118C12.3201 3.67047 13.0892 4.60754 13.5433 5.7039C13.9974 6.80026 14.1162 8.00666 13.8847 9.17054C13.6532 10.3344 13.0818 11.4035 12.2426 12.2426C11.4035 13.0818 10.3344 13.6532 9.17055 13.8847C8.00666 14.1162 6.80026 13.9974 5.7039 13.5433C4.60755 13.0892 3.67047 12.3201 3.01119 11.3334C2.3519 10.3467 2 9.18669 2 8C2 6.4087 2.63214 4.88258 3.75736 3.75736C4.88258 2.63214 6.4087 2 8 2V2ZM8 1C6.61553 1 5.26216 1.41054 4.11101 2.17971C2.95987 2.94888 2.06266 4.04213 1.53285 5.32122C1.00303 6.6003 0.86441 8.00777 1.13451 9.36563C1.4046 10.7235 2.07129 11.9708 3.05026 12.9497C4.02922 13.9287 5.2765 14.5954 6.63437 14.8655C7.99224 15.1356 9.3997 14.997 10.6788 14.4672C11.9579 13.9373 13.0511 13.0401 13.8203 11.889C14.5895 10.7378 15 9.38447 15 8C14.9989 6.14381 14.2611 4.36395 12.9486 3.05142C11.6361 1.7389 9.85619 1.00106 8 1V1Z"
        fill="#232942"
      />
      <path
        d="M7.86722 5.83209C8.23615 5.83209 8.53522 5.53302 8.53522 5.16409C8.53522 4.79517 8.23615 4.49609 7.86722 4.49609C7.49829 4.49609 7.19922 4.79517 7.19922 5.16409C7.19922 5.53302 7.49829 5.83209 7.86722 5.83209Z"
        fill="#232942"
      />
      <path
        d="M8.82378 11.0001H8.54778V7.53613C8.54778 7.40352 8.4951 7.27635 8.40133 7.18258C8.30757 7.08881 8.18039 7.03613 8.04778 7.03613H7.17578C7.04317 7.03613 6.916 7.08881 6.82223 7.18258C6.72846 7.27635 6.67578 7.40352 6.67578 7.53613C6.67578 7.66874 6.72846 7.79592 6.82223 7.88969C6.916 7.98345 7.04317 8.03613 7.17578 8.03613H7.54778V11.0001H7.17578C7.04317 11.0001 6.916 11.0528 6.82223 11.1466C6.72846 11.2403 6.67578 11.3675 6.67578 11.5001C6.67578 11.6327 6.72846 11.7599 6.82223 11.8537C6.916 11.9475 7.04317 12.0001 7.17578 12.0001H8.82378C8.95639 12.0001 9.08357 11.9475 9.17733 11.8537C9.2711 11.7599 9.32378 11.6327 9.32378 11.5001C9.32378 11.3675 9.2711 11.2403 9.17733 11.1466C9.08357 11.0528 8.95639 11.0001 8.82378 11.0001V11.0001Z"
        fill="#232942"
      />
    </svg>
  );
}
