import { SVGProps } from "react";

export default function UserIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      {...props}
    >
      <path
        className="strokeIcon"
        d="M7.99966 2C8.42528 2 8.84135 2.12621 9.19525 2.36268C9.54914 2.59914 9.82497 2.93524 9.98785 3.32847C10.1507 3.72169 10.1933 4.15439 10.1103 4.57183C10.0273 4.98928 9.82231 5.37273 9.52135 5.67369C9.22039 5.97466 8.83694 6.17961 8.41949 6.26265C8.00205 6.34569 7.56935 6.30307 7.17612 6.14019C6.7829 5.97731 6.4468 5.70148 6.21034 5.34759C5.97387 4.99369 5.84766 4.57763 5.84766 4.152C5.84766 3.58125 6.07439 3.03388 6.47796 2.63031C6.88154 2.22673 7.42891 2 7.99966 2ZM7.99966 1C7.37625 1 6.76684 1.18486 6.2485 1.53121C5.73016 1.87755 5.32616 2.36983 5.08759 2.94578C4.84902 3.52173 4.7866 4.1555 4.90822 4.76692C5.02984 5.37835 5.33004 5.93999 5.77086 6.3808C6.21167 6.82162 6.77331 7.12181 7.38473 7.24343C7.99616 7.36506 8.62992 7.30264 9.20588 7.06407C9.78183 6.8255 10.2741 6.4215 10.6205 5.90316C10.9668 5.38481 11.1517 4.77541 11.1517 4.152C11.1517 3.31604 10.8196 2.51431 10.2285 1.9232C9.63734 1.33208 8.83562 1 7.99966 1Z"
      />
      <path
        className="strokeIcon"
        d="M4.72778 8.64803C5.65358 9.39754 6.80863 9.80649 7.99978 9.80649C9.19094 9.80649 10.346 9.39754 11.2718 8.64803C12.0099 9.13091 12.6331 9.76979 13.0975 10.5196C13.562 11.2695 13.8563 12.1121 13.9598 12.988C12.0444 13.6589 10.0293 14.0011 7.99978 14C5.97027 14.0011 3.95519 13.6589 2.03978 12.988C2.14327 12.1121 2.4376 11.2695 2.90203 10.5196C3.36645 9.76979 3.98968 9.13091 4.72778 8.64803ZM11.2558 7.62003C11.0557 7.61832 10.8614 7.68779 10.7078 7.81603C9.94981 8.45434 8.99073 8.80438 7.99978 8.80438C7.00884 8.80438 6.04976 8.45434 5.29178 7.81603C5.13813 7.68779 4.94391 7.61832 4.74378 7.62003C4.57534 7.61865 4.41008 7.66587 4.26778 7.75603C3.35594 8.32919 2.58901 9.10548 2.02695 10.0242C1.46488 10.9429 1.12292 11.9792 1.02778 13.052C1.01163 13.2275 1.05384 13.4033 1.14787 13.5523C1.24189 13.7013 1.38247 13.8151 1.54778 13.876C3.6177 14.6194 5.80045 14.9996 7.99978 15C10.1991 14.9996 12.3819 14.6194 14.4518 13.876C14.6171 13.8151 14.7577 13.7013 14.8517 13.5523C14.9457 13.4033 14.9879 13.2275 14.9718 13.052C14.8767 11.9792 14.5347 10.9429 13.9726 10.0242C13.4106 9.10548 12.6436 8.32919 11.7318 7.75603C11.5895 7.66587 11.4242 7.61865 11.2558 7.62003Z"
      />
      <path
        d="M8 7.32A3.16 3.16 0 1 0 8 1a3.16 3.16 0 0 0 0 6.32Z"
        fill="currentColor"
        className="filledIcon"
      />
      <path
        fillRule="evenodd"
        className="filledIcon"
        clipRule="evenodd"
        d="M11.72 7.76c-.32-.2-.72-.16-1.04.04a4.177 4.177 0 0 1-5.4 0 .84.84 0 0 0-1.04-.04c-1.84 1.16-3 3.12-3.2 5.28-.04.36.2.68.52.84 4.16 1.48 8.72 1.48 12.92 0 .32-.12.56-.48.52-.84-.24-2.16-1.4-4.12-3.28-5.28Z"
        fill="currentColor"
      />
    </svg>
  );
}
