import { SVGProps } from "react";

export default function LockIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.6632 5.07185H10.1752V4.22385C10.1752 3.11631 9.73519 2.05413 8.95203 1.27097C8.16888 0.487822 7.1067 0.0478516 5.99916 0.0478516C4.89161 0.0478516 3.82943 0.487822 3.04628 1.27097C2.26313 2.05413 1.82316 3.11631 1.82316 4.22385V5.07185H1.33516C1.12298 5.07185 0.9195 5.15614 0.769471 5.30617C0.619442 5.45619 0.535156 5.65968 0.535156 5.87185V8.53585C0.535156 9.25339 0.676487 9.96391 0.951078 10.6268C1.22567 11.2898 1.62815 11.8921 2.13552 12.3995C2.6429 12.9069 3.24525 13.3093 3.90817 13.5839C4.5711 13.8585 5.28161 13.9999 5.99916 13.9999C6.7167 13.9999 7.42721 13.8585 8.09014 13.5839C8.75306 13.3093 9.35541 12.9069 9.86279 12.3995C10.3702 11.8921 10.7726 11.2898 11.0472 10.6268C11.3218 9.96391 11.4632 9.25339 11.4632 8.53585V5.87185C11.4632 5.65968 11.3789 5.45619 11.2288 5.30617C11.0788 5.15614 10.8753 5.07185 10.6632 5.07185ZM2.82316 5.07185V4.22385C2.82316 3.38152 3.15777 2.5737 3.75339 1.97808C4.349 1.38247 5.15683 1.04785 5.99916 1.04785C6.84148 1.04785 7.64931 1.38247 8.24493 1.97808C8.84054 2.5737 9.17516 3.38152 9.17516 4.22385V5.07185H2.82316ZM6.49916 10.4079C6.49916 10.5405 6.44648 10.6676 6.35271 10.7614C6.25894 10.8552 6.13176 10.9079 5.99916 10.9079C5.86655 10.9079 5.73937 10.8552 5.6456 10.7614C5.55183 10.6676 5.49916 10.5405 5.49916 10.4079V8.86385C5.49916 8.73124 5.55183 8.60407 5.6456 8.5103C5.73937 8.41653 5.86655 8.36385 5.99916 8.36385C6.13176 8.36385 6.25894 8.41653 6.35271 8.5103C6.44648 8.60407 6.49916 8.73124 6.49916 8.86385V10.4079Z"
        fill="white"
      />
    </svg>
  );
}
