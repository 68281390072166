import { SVGProps } from "react";

export default function SecureIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.00042 2.08813L12.6844 4.08813V6.40013C12.684 7.48479 12.4514 8.5568 12.0022 9.54409C11.5531 10.5314 10.8978 11.4111 10.0804 12.1241L8.00042 13.9401L5.92042 12.1241C5.10306 11.4111 4.44778 10.5314 3.99863 9.54409C3.54948 8.5568 3.31687 7.48479 3.31642 6.40013V4.10413L8.00042 2.08813ZM8.00042 1.03613C7.94556 1.03673 7.89129 1.04758 7.84042 1.06813L2.56042 3.34013C2.48749 3.37102 2.42537 3.42286 2.38194 3.48908C2.3385 3.55531 2.3157 3.63294 2.31642 3.71213V6.40013C2.3164 7.62834 2.57909 8.84234 3.08686 9.96067C3.59462 11.079 4.33572 12.0758 5.26042 12.8841L7.46442 14.8001C7.61142 14.9328 7.8024 15.0062 8.00042 15.0062C8.19844 15.0062 8.38942 14.9328 8.53642 14.8001L10.7404 12.8761C11.6641 12.0687 12.4046 11.0732 12.9123 9.95632C13.4201 8.83945 13.6833 7.62699 13.6844 6.40013V3.71213C13.6851 3.63294 13.6623 3.55531 13.6189 3.48908C13.5755 3.42286 13.5134 3.37102 13.4404 3.34013L8.16042 1.06813C8.10955 1.04758 8.05528 1.03673 8.00042 1.03613Z"
        fill="#00A27F"
      />
      <path
        d="M7.99966 4.59974L10.6517 5.73974V6.97574C10.6538 7.62441 10.5163 8.26594 10.2485 8.85675C9.98071 9.44756 9.58889 9.9738 9.09966 10.3997L7.99966 11.3477L6.89966 10.3997C6.41246 9.97557 6.02184 9.45191 5.75412 8.86403C5.4864 8.27615 5.3478 7.63771 5.34766 6.99174V5.73974L7.99966 4.59974ZM7.99966 3.53174C7.9636 3.53155 7.92799 3.53977 7.89566 3.55574L4.50766 5.01174C4.46088 5.03233 4.42096 5.06586 4.39261 5.10838C4.36427 5.1509 4.34867 5.20065 4.34766 5.25174V6.97574C4.34796 7.76399 4.51695 8.54305 4.84329 9.26057C5.16962 9.9781 5.64574 10.6175 6.23966 11.1357L7.65566 12.3717C7.75026 12.4564 7.87273 12.5031 7.99966 12.5031C8.12658 12.5031 8.24905 12.4564 8.34366 12.3717L9.75966 11.1357C10.3536 10.6175 10.8297 9.9781 11.156 9.26057C11.4824 8.54305 11.6514 7.76399 11.6517 6.97574V5.25174C11.6506 5.20065 11.635 5.1509 11.6067 5.10838C11.5784 5.06586 11.5384 5.03233 11.4917 5.01174L8.10366 3.55574C8.07133 3.53977 8.03572 3.53155 7.99966 3.53174Z"
        fill="#00A27F"
      />
    </svg>
  );
}
