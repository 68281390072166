import { SVGProps } from "react";

export default function TextFileIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Capa_1"
      data-name="Capa 1"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      {...props}
    >
      <defs>
        <style>
          {
            ".cls-1{fill:none;stroke:#000;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px}.cls-2{stroke-width:0.75px}"
          }
        </style>
      </defs>
      <path
        d="M14.51 5.41c0 .83.67 1.5 1.5 1.5h2.09c.34 0 .51-.41.27-.65l-3.9-3.9a.429.429 0 0 0-.73.27v2.78Z"
        className="cls-1"
      />
      <path
        d="M15.21 2.89H6.63c-.55 0-1 .45-1 1v16.46c0 .55.45 1 1 1h14.13c.55 0 1-.45 1-1V8.87l-5.53-5.53Z"
        className="cls-1"
      />
      <path
        d="M8.32 12.92H6.98v-.96h4.05v.96H9.03v3.85H8.32v-3.85Z"
        className="cls-2"
      />
      <path
        d="m14.54 16.99-1.24-1.78-1.21 1.78h-1.36l1.88-2.58-1.8-2.51h1.34l1.11 1.56 1.09-1.56h1.22l-1.74 2.41 1.87 2.6h-1.55Z"
        className="cls-2"
      />
      <path
        d="M18.11 12.92H15.65v-.96h4.05v.96H18.11v3.85h-1.36v-3.85Z"
        className="cls-2"
      />
    </svg>
  );
}
