import { SVGProps } from "react";

export default function LeaseIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={40}
      height={40}
      fill="none"
      viewBox="0 0 40 40"
      {...props}
    >
      <path
        fill="#232942"
        d="m20 5.21 14.21 14.21H32a2.49 2.49 0 0 0-2.5 2.5V35h-3.73v-6.08A5.77 5.77 0 0 0 20 23.16h-.31a5.88 5.88 0 0 0-5.46 5.95V35h-3.75V21.92A2.49 2.49 0 0 0 8 19.42H5.79L20 5.21Zm0-2.71a2 2 0 0 0-1.41.59L3.17 18.5a2 2 0 0 0 1.41 3.42H8V35.5a2 2 0 0 0 2 2h4.75a2 2 0 0 0 2-2v-6.39a3.4 3.4 0 0 1 3.09-3.45H20a3.27 3.27 0 0 1 3.27 3.26v6.58a2 2 0 0 0 2 2H30a2 2 0 0 0 2-2V21.92h3.4a2 2 0 0 0 1.41-3.42L21.41 3.09A2 2 0 0 0 20 2.5Z"
      />
    </svg>
  );
}
