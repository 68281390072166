import { SVGProps } from "react";

export default function ErrorIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="24" height="24" rx="12" fill="#DC4B5C" />
      <path
        d="M13.1328 11.9998L16.4032 8.72297C16.5526 8.5736 16.6365 8.37101 16.6365 8.15977C16.6365 7.94853 16.5526 7.74594 16.4032 7.59657C16.2538 7.4472 16.0512 7.36328 15.84 7.36328C15.6288 7.36328 15.4262 7.4472 15.2768 7.59657L12 10.867L8.72319 7.59657C8.57382 7.4472 8.37123 7.36328 8.15999 7.36328C7.94875 7.36328 7.74616 7.4472 7.59679 7.59657C7.44742 7.74594 7.36351 7.94853 7.36351 8.15977C7.36351 8.37101 7.44742 8.5736 7.59679 8.72297L10.8672 11.9998L7.59679 15.2766C7.52249 15.3503 7.46351 15.4381 7.42327 15.5347C7.38302 15.6314 7.3623 15.7351 7.3623 15.8398C7.3623 15.9445 7.38302 16.0481 7.42327 16.1448C7.46351 16.2415 7.52249 16.3292 7.59679 16.403C7.67012 16.4781 7.75771 16.5377 7.85443 16.5785C7.95115 16.6192 8.05504 16.6402 8.15999 16.6402C8.26494 16.6402 8.36883 16.6192 8.46555 16.5785C8.56227 16.5377 8.64987 16.4781 8.72319 16.403L12 13.1326L15.2768 16.403C15.3501 16.4781 15.4377 16.5377 15.5344 16.5785C15.6312 16.6192 15.735 16.6402 15.84 16.6402C15.9449 16.6402 16.0488 16.6192 16.1456 16.5785C16.2423 16.5377 16.3299 16.4781 16.4032 16.403C16.4775 16.3292 16.5365 16.2415 16.5767 16.1448C16.617 16.0481 16.6377 15.9445 16.6377 15.8398C16.6377 15.7351 16.617 15.6314 16.5767 15.5347C16.5365 15.4381 16.4775 15.3503 16.4032 15.2766L13.1328 11.9998Z"
        fill="white"
      />
    </svg>
  );
}
