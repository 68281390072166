import { SVGProps } from "react";

export default function CheckIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M5.88169 12.6398C5.77666 12.6406 5.67257 12.62 5.57575 12.5793C5.47893 12.5386 5.3914 12.4786 5.31849 12.403L1.86889 8.95981C1.71867 8.80959 1.63428 8.60585 1.63428 8.39341C1.63428 8.18097 1.71867 7.97723 1.86889 7.82701C2.01911 7.67679 2.22285 7.5924 2.43529 7.5924C2.64773 7.5924 2.85147 7.67679 3.00169 7.82701L5.88169 10.707L12.9985 3.59661C13.0708 3.51528 13.1589 3.44956 13.2575 3.40346C13.3561 3.35736 13.463 3.33185 13.5718 3.3285C13.6805 3.32516 13.7889 3.34403 13.8901 3.38398C13.9913 3.42393 14.0833 3.48411 14.1605 3.56084C14.2376 3.63757 14.2983 3.72923 14.3389 3.83023C14.3794 3.93122 14.3989 4.03942 14.3961 4.14821C14.3934 4.257 14.3685 4.36408 14.323 4.46292C14.2774 4.56175 14.2122 4.65025 14.1313 4.72301L6.45129 12.403C6.37764 12.4794 6.28907 12.5398 6.19108 12.5806C6.0931 12.6213 5.98779 12.6415 5.88169 12.6398Z"
        fill="#232942"
      />
    </svg>
  );
}
