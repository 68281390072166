import { SVGProps } from "react";

function ChangePasswordIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.4362 1.56386C14.2593 1.38655 14.0492 1.24588 13.8179 1.1499C13.5866 1.05392 13.3386 1.00452 13.0882 1.00452C12.8377 1.00452 12.5898 1.05392 12.3585 1.1499C12.1271 1.24588 11.917 1.38655 11.7402 1.56386L1.76817 11.5359L1.03617 14.5439C1.02345 14.595 1.02251 14.6484 1.03342 14.6999C1.04434 14.7515 1.06683 14.7999 1.09918 14.8415C1.13154 14.8831 1.17293 14.9168 1.22022 14.9401C1.26751 14.9634 1.31946 14.9756 1.37217 14.9759C1.40004 14.9797 1.4283 14.9797 1.45617 14.9759L4.46417 14.2439L14.4362 4.25986C14.7933 3.90213 14.9939 3.41732 14.9939 2.91186C14.9939 2.40639 14.7933 1.92158 14.4362 1.56386ZM3.95617 13.3279L2.26017 13.7279L2.66017 12.0319L11.1202 3.59986L12.4002 4.87986L3.95617 13.3279ZM13.7282 3.55586L13.1122 4.17186L11.8282 2.88786L12.4442 2.27186C12.5281 2.18576 12.6283 2.11719 12.7389 2.07011C12.8495 2.02304 12.9684 1.9984 13.0886 1.99763C13.2088 1.99686 13.328 2.01997 13.4392 2.06562C13.5504 2.11127 13.6515 2.17855 13.7365 2.26356C13.8215 2.34857 13.8888 2.44961 13.9344 2.56083C13.9801 2.67205 14.0032 2.79122 14.0024 2.91144C14.0016 3.03166 13.977 3.15053 13.9299 3.26115C13.8828 3.37177 13.8143 3.47195 13.7282 3.55586Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default ChangePasswordIcon;
