import { SVGProps } from "react";

export default function SuccessfulPaymentImage(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 104 64"
      {...props}
    >
      <path
        fill="#EEF3FE"
        d="M98.903 41.528a3.2 3.2 0 0 1-2.264-.936l-6.88-6.88-6.88 6.88a3.2 3.2 0 0 1-4.48-4.528l9.136-9.136a3.198 3.198 0 0 1 4.528 0l9.144 9.136a3.202 3.202 0 0 1-.485 4.925 3.197 3.197 0 0 1-1.779.539h-.04Z"
      />
      <path
        fill="#EEF3FE"
        d="M98.903 27.288a3.2 3.2 0 0 1-2.264-.936l-6.88-6.88-6.88 6.88a3.2 3.2 0 0 1-4.48-4.528l9.136-9.144a3.201 3.201 0 0 1 4.528 0l9.144 9.144a3.203 3.203 0 0 1 .695 3.49 3.202 3.202 0 0 1-2.959 1.974h-.04ZM97.497 10.296a3.2 3.2 0 1 1 3.2-3.2 3.252 3.252 0 0 1-3.2 3.2Zm0-4.88a1.64 1.64 0 1 0-.08 3.279 1.64 1.64 0 0 0 .08-3.279ZM4.103 8.32a3.24 3.24 0 1 1 0-6.48 3.24 3.24 0 0 1 0 6.48Zm0-4.88a1.64 1.64 0 1 0 0 3.28 1.64 1.64 0 0 0 0-3.28ZM10.76 15.92a1.8 1.8 0 1 0 .001-3.6 1.8 1.8 0 0 0 0 3.6Z"
      />
      <path
        fill="#fff"
        d="M86.583 7.056h-69.16c-1.03 0-1.864.834-1.864 1.864v45.8c0 1.03.834 1.864 1.864 1.864h69.16c1.03 0 1.864-.835 1.864-1.864V8.92c0-1.03-.835-1.864-1.864-1.864Z"
      />
      <path
        fill="#232942"
        d="M86.575 56.976H17.423a2.264 2.264 0 0 1-2.224-2.264V8.92a2.264 2.264 0 0 1 2.264-2.264h69.152A2.264 2.264 0 0 1 88.88 8.92v45.792a2.262 2.262 0 0 1-2.304 2.264ZM17.423 7.456A1.464 1.464 0 0 0 16 8.92v45.792a1.464 1.464 0 0 0 1.464 1.464h69.152a1.464 1.464 0 0 0 1.464-1.464V8.92a1.464 1.464 0 0 0-1.464-1.464H17.423Z"
      />
      <path
        fill="#D1DFFD"
        d="M17.423 7.056h69.152a1.864 1.864 0 0 1 1.864 1.864v3.408h-72.88V8.92a1.864 1.864 0 0 1 1.864-1.864Z"
      />
      <path
        fill="#232942"
        d="M88.44 12.728H15.56a.4.4 0 0 1-.4-.4V8.92a2.264 2.264 0 0 1 2.264-2.264h69.152A2.264 2.264 0 0 1 88.84 8.92v3.408a.4.4 0 0 1-.4.4Zm-72.48-.8h72.08V8.92a1.464 1.464 0 0 0-1.464-1.464H17.424A1.464 1.464 0 0 0 16 8.92l-.04 3.008Z"
      />
      <path
        fill="#fff"
        d="M19.413 10.816a1.128 1.128 0 1 0 0-2.257 1.128 1.128 0 0 0 0 2.257Z"
      />
      <path
        fill="#232942"
        d="M19.415 11.2a1.528 1.528 0 1 1 0-3.057 1.528 1.528 0 0 1 0 3.057Zm0-2.256a.728.728 0 1 0 .728.728.728.728 0 0 0-.728-.712v-.016Z"
      />
      <path
        fill="#fff"
        d="M24.116 10.816a1.128 1.128 0 1 0 0-2.256 1.128 1.128 0 0 0 0 2.256Z"
      />
      <path
        fill="#232942"
        d="M24.118 11.2a1.528 1.528 0 1 1 0-3.057 1.528 1.528 0 0 1 0 3.057Zm0-2.256a.728.728 0 1 0 .728.728.728.728 0 0 0-.728-.712v-.016Z"
      />
      <path
        fill="#fff"
        d="M28.831 10.815a1.128 1.128 0 1 0 0-2.256 1.128 1.128 0 0 0 0 2.256Z"
      />
      <path
        fill="#232942"
        d="M28.8 11.2a1.527 1.527 0 1 1 1.6-1.513 1.52 1.52 0 0 1-1.6 1.512Zm0-2.257a.728.728 0 1 0 .728.728.728.728 0 0 0-.728-.712v-.016Z"
      />
      <path
        fill="#4CBF8C"
        d="M51.998 47.872c7.41 0 13.416-6.007 13.416-13.416 0-7.41-6.007-13.416-13.416-13.416-7.41 0-13.416 6.007-13.416 13.416 0 7.41 6.007 13.416 13.416 13.416Z"
      />
      <path
        fill="#232942"
        d="M52 48.272a13.824 13.824 0 1 1 13.816-13.816A13.832 13.832 0 0 1 52 48.272Zm0-26.84a13.024 13.024 0 1 0 13.016 13.024A13.032 13.032 0 0 0 52 21.432Z"
      />
      <path
        fill="#fff"
        d="m50.327 41.6-5.368-5.68a1.769 1.769 0 1 1 2.568-2.4l2.752 2.92 6.96-7.624a1.77 1.77 0 0 1 3.075 1.127 1.773 1.773 0 0 1-.467 1.273L50.327 41.6Z"
      />
      <path
        fill="#232942"
        d="M50.328 41.96a.417.417 0 0 1-.296-.128l-5.36-5.68a2.163 2.163 0 0 1 1.634-3.645c.573.017 1.116.26 1.51.677l2.464 2.6 6.664-7.304a2.17 2.17 0 0 1 3.124-.21 2.167 2.167 0 0 1 .076 3.13l-9.52 10.4a.48.48 0 0 1-.296.16Zm-4.088-8.656a1.36 1.36 0 0 0-.992 2.296l5.072 5.368 9.232-10.112a1.368 1.368 0 0 0-2.016-1.84l-6.96 7.624a.39.39 0 0 1-.584 0l-2.752-2.92a1.384 1.384 0 0 0-1-.416Z"
      />
      <path
        fill="#DEDFE4"
        d="M12.672 39.936c6.08 0 11.008-4.928 11.008-11.008 0-6.08-4.928-11.008-11.008-11.008-6.08 0-11.008 4.929-11.008 11.008 0 6.08 4.929 11.008 11.008 11.008Z"
      />
      <path
        fill="#232942"
        d="M12.674 40.328a11.409 11.409 0 1 1 11.408-11.4 11.416 11.416 0 0 1-11.408 11.4Zm0-22.008a10.608 10.608 0 1 0 10.608 10.608A10.624 10.624 0 0 0 12.674 18.32Z"
      />
      <path
        fill="#232942"
        d="M15.547 33.04a4 4 0 0 1-2.4.896v1.6h-.92v-1.6a7.11 7.11 0 0 1-1.944-.384 5.121 5.121 0 0 1-1.048-.512.592.592 0 0 1-.208-.76.608.608 0 0 1 .872-.28c.225.14.464.258.712.352.512.198 1.051.32 1.6.36v-3.224a10.702 10.702 0 0 1-1.6-.52 2.745 2.745 0 0 1-1.096-.8 2.184 2.184 0 0 1-.44-1.432 2.52 2.52 0 0 1 .8-1.88 3.808 3.808 0 0 1 2.4-.896v-1.6h.848v1.6c.526.02 1.047.106 1.552.256.275.083.543.19.8.32a.607.607 0 0 1 .28.8.584.584 0 0 1-.8.312 4.878 4.878 0 0 0-1.848-.488v3.272c.575.133 1.14.31 1.688.528.429.174.81.449 1.112.8.32.41.482.921.456 1.44a2.488 2.488 0 0 1-.816 1.84Zm-4.656-5.448c.408.266.86.455 1.336.56V25.12a2.4 2.4 0 0 0-1.344.528 1.295 1.295 0 0 0-.48 1 1.104 1.104 0 0 0 .488.944Zm3.648 4.656a1.281 1.281 0 0 0 .464-1.008 1.072 1.072 0 0 0-.48-.944 4.797 4.797 0 0 0-1.4-.584v3.016a2.528 2.528 0 0 0 1.416-.48ZM13.232 58.248a.4.4 0 0 1-.4-.4v-3.04a.4.4 0 0 1 .8 0v3.04a.4.4 0 0 1-.4.4Zm0-5.44a.4.4 0 0 1-.4-.4v-8a.4.4 0 0 1 .8 0v8a.4.4 0 0 1-.4.392v.008ZM7.49 54.352a.4.4 0 0 1-.4-.4V51.24a.4.4 0 0 1 .4-.4.408.408 0 0 1 .4.4v2.712a.4.4 0 0 1-.4.4Zm0-5.112a.4.4 0 0 1-.4-.4v-5.6a.4.4 0 0 1 .4-.4.408.408 0 0 1 .4.4v5.6a.4.4 0 0 1-.4.4Z"
      />
      <path
        fill="#D1DFFD"
        d="M99.663 40.664H70.67a3.456 3.456 0 0 0-3.456 3.456v15.816a3.456 3.456 0 0 0 3.456 3.456h28.992a3.456 3.456 0 0 0 3.456-3.456V44.12a3.456 3.456 0 0 0-3.456-3.456Z"
      />
      <path
        fill="#232942"
        d="M99.665 63.792h-29a3.864 3.864 0 0 1-3.856-3.856V44.12a3.864 3.864 0 0 1 3.856-3.856h29a3.855 3.855 0 0 1 3.848 3.856v15.816a3.858 3.858 0 0 1-3.848 3.856Zm-29-22.728a3.064 3.064 0 0 0-3.056 3.056v15.816a3.064 3.064 0 0 0 3.056 3.056h29a3.055 3.055 0 0 0 3.048-3.056V44.12a3.054 3.054 0 0 0-3.048-3.056h-29Z"
      />
      <path fill="#fff" d="M103.119 44.72H67.215v3.56h35.904v-3.56Z" />
      <path
        fill="#232942"
        d="M103.113 48.672H67.201a.4.4 0 0 1-.4-.4V44.72a.4.4 0 0 1 .4-.4h35.904a.4.4 0 0 1 .4.4v3.552a.398.398 0 0 1-.392.4Zm-35.504-.8h35.104V45.12H67.609v2.752ZM86.79 58.896H71.448a.4.4 0 1 1 0-.8H86.79a.4.4 0 1 1 0 .8ZM78.75 56.872h-7.303a.4.4 0 0 1 0-.8h7.304a.4.4 0 1 1 0 .8Z"
      />
      <path
        fill="#DEDFE4"
        d="M98.36 59.768a2.36 2.36 0 1 0 0-4.72 2.36 2.36 0 0 0 0 4.72Z"
      />
      <path
        fill="#232942"
        d="M98.4 60.168a2.76 2.76 0 1 1 0-5.52 2.76 2.76 0 0 1 0 5.52Zm0-4.72a1.96 1.96 0 1 0 1.961 1.96 1.97 1.97 0 0 0-1.96-1.96Z"
      />
      <path
        fill="#DEDFE4"
        d="M95.126 59.768a2.36 2.36 0 1 0 0-4.72 2.36 2.36 0 0 0 0 4.72Z"
      />
      <path
        fill="#232942"
        d="M95.127 60.168a2.76 2.76 0 1 1 .016-5.52 2.76 2.76 0 0 1-.016 5.52Zm0-4.72a1.96 1.96 0 1 0 .016 3.92 1.96 1.96 0 0 0-.016-3.92ZM65.416 51.656a.4.4 0 0 1 .4.4v2.712a.4.4 0 0 1-.4.4.408.408 0 0 1-.4-.4v-2.712a.4.4 0 0 1 .4-.4Zm0 5.112a.4.4 0 0 1 .4.4v5.6a.4.4 0 0 1-.4.4.408.408 0 0 1-.4-.4v-5.6a.4.4 0 0 1 .4-.368v-.032Z"
      />
    </svg>
  );
}
