import { SVGProps } from "react";

function CopyIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14 4.82031V14.0003H6.66797V4.82031H14ZM14.2 3.82031H6.46797C6.2558 3.82031 6.05231 3.9046 5.90228 4.05463C5.75225 4.20466 5.66797 4.40814 5.66797 4.62031V14.2203C5.66797 14.4325 5.75225 14.636 5.90228 14.786C6.05231 14.936 6.2558 15.0203 6.46797 15.0203H14.2C14.4121 15.0203 14.6156 14.936 14.7657 14.786C14.9157 14.636 15 14.4325 15 14.2203V4.62031C15 4.40814 14.9157 4.20466 14.7657 4.05463C14.6156 3.9046 14.4121 3.82031 14.2 3.82031Z"
        fill="#232942"
      />
      <path
        d="M3.94006 11.7638H2.20806C1.8898 11.7638 1.58457 11.6374 1.35953 11.4123C1.13448 11.1873 1.00806 10.8821 1.00806 10.5638V2.20781C1.00806 1.88955 1.13448 1.58433 1.35953 1.35928C1.58457 1.13424 1.8898 1.00781 2.20806 1.00781H8.95606C9.27432 1.00781 9.57954 1.13424 9.80458 1.35928C10.0296 1.58433 10.1561 1.88955 10.1561 2.20781C10.1561 2.34042 10.1034 2.4676 10.0096 2.56137C9.91584 2.65513 9.78866 2.70781 9.65606 2.70781C9.52345 2.70781 9.39627 2.65513 9.3025 2.56137C9.20874 2.4676 9.15606 2.34042 9.15606 2.20781C9.15772 2.18074 9.15376 2.15362 9.1444 2.12816C9.13505 2.1027 9.12051 2.07946 9.10171 2.05991C9.08291 2.04036 9.06026 2.02493 9.03519 2.01458C9.01012 2.00424 8.98317 1.99921 8.95606 1.99981H2.20806C2.1557 1.9998 2.10543 2.02032 2.06803 2.05697C2.03064 2.09362 2.0091 2.14347 2.00806 2.19581V10.5558C2.0091 10.6082 2.03064 10.658 2.06803 10.6947C2.10543 10.7313 2.1557 10.7518 2.20806 10.7518H3.94006C4.07266 10.7518 4.19984 10.8045 4.29361 10.8983C4.38738 10.992 4.44006 11.1192 4.44006 11.2518C4.44006 11.3844 4.38738 11.5116 4.29361 11.6054C4.19984 11.6991 4.07266 11.7518 3.94006 11.7518V11.7638Z"
        fill="#232942"
      />
    </svg>
  );
}

export default CopyIcon;
