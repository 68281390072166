import { SVGProps } from "react";

export default function GreenArrowIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.39949 12.5002H14C14.8284 12.5002 15.5 13.1717 15.5 14.0002C15.5 14.8286 14.8284 15.5002 14 15.5002H2C1.17157 15.5002 0.5 14.8286 0.5 14.0002V2.00015C0.5 1.17173 1.17157 0.500153 2 0.500153C2.82843 0.500153 3.5 1.17173 3.5 2.00015V10.157L12.9393 0.71766C13.5251 0.131874 14.4749 0.131874 15.0607 0.71766C15.6464 1.30345 15.6464 2.25319 15.0607 2.83898L5.39949 12.5002Z"
        fill="#4CBF8C"
      />
    </svg>
  );
}
