import { SVGProps } from "react";

export default function HomeIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M7.99989 2.084L13.6839 7.768H12.7999C12.6684 7.76747 12.5381 7.79298 12.4166 7.84304C12.295 7.89311 12.1846 7.96675 12.0916 8.05971C11.9986 8.15268 11.925 8.26313 11.8749 8.38469C11.8249 8.50626 11.7994 8.63653 11.7999 8.768V14H10.3079V11.568C10.3068 10.9566 10.0632 10.3706 9.63048 9.93858C9.19776 9.50661 8.61132 9.264 7.99989 9.264H7.87589C7.2768 9.30684 6.71679 9.57725 6.3107 10.0198C5.90461 10.4623 5.68321 11.0434 5.69189 11.644V14H4.19189V8.768C4.19242 8.6372 4.16717 8.50758 4.11761 8.38654C4.06804 8.2655 3.99511 8.15541 3.903 8.06255C3.81088 7.96969 3.70138 7.89588 3.58074 7.84534C3.4601 7.79481 3.33069 7.76852 3.19989 7.768H2.31589L7.99989 2.084ZM7.99989 1C7.78806 1.00089 7.58523 1.08576 7.43589 1.236L1.26789 7.4C1.15531 7.51166 1.07845 7.65425 1.04707 7.80967C1.01569 7.9651 1.03121 8.12634 1.09164 8.27293C1.15208 8.41952 1.25471 8.54484 1.38651 8.63299C1.51831 8.72113 1.67333 8.76813 1.83189 8.768H3.19989V14.2C3.19989 14.4122 3.28417 14.6157 3.4342 14.7657C3.58423 14.9157 3.78772 15 3.99989 15H5.89989C6.11206 15 6.31555 14.9157 6.46557 14.7657C6.6156 14.6157 6.69989 14.4122 6.69989 14.2V11.644C6.69341 11.3004 6.81728 10.967 7.04658 10.711C7.27589 10.455 7.59362 10.2953 7.93589 10.264H7.99989C8.3461 10.264 8.67819 10.4013 8.92337 10.6457C9.16856 10.8901 9.30683 11.2218 9.30789 11.568V14.2C9.30789 14.4122 9.39218 14.6157 9.5422 14.7657C9.69223 14.9157 9.89572 15 10.1079 15H11.9999C12.2121 15 12.4155 14.9157 12.5656 14.7657C12.7156 14.6157 12.7999 14.4122 12.7999 14.2V8.768H14.1599C14.3184 8.76813 14.4735 8.72113 14.6053 8.63299C14.7371 8.54484 14.8397 8.41952 14.9001 8.27293C14.9606 8.12634 14.9761 7.9651 14.9447 7.80967C14.9133 7.65425 14.8365 7.51166 14.7239 7.4L8.56389 1.236C8.41455 1.08576 8.21172 1.00089 7.99989 1Z"
        fill="#232942"
      />
    </svg>
  );
}
