import { SVGProps } from "react";

export default function VoidIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 512 512"
      {...props}
    >
      <path d="M256 0C114.84 0 0 114.842 0 256s114.84 256 256 256 256-114.842 256-256S397.16 0 256 0zM49.548 256c0-113.837 92.614-206.452 206.452-206.452 48.011 0 92.246 16.473 127.351 44.065L93.613 383.351C66.022 348.246 49.548 304.011 49.548 256zM256 462.452c-48.011 0-92.246-16.473-127.351-44.065l289.738-289.738c27.592 35.105 44.065 79.34 44.065 127.351 0 113.837-92.615 206.452-206.452 206.452z" />
    </svg>
  );
}
