import { SVGProps } from "react";

export default function LogoCF(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M56.9695 59.9871H3.02724C1.78 59.9871 0.700119 59.2221 0.241867 58.1382C0.0864596 57.7756 0.0027771 57.3811 0.0027771 56.9627V3.02447C0.0027771 1.92467 0.596514 0.95636 1.48114 0.426382C1.93541 0.155416 2.4614 0 3.02724 0H56.9655C57.7983 0 58.5554 0.338706 59.1014 0.888608C59.6513 1.43851 59.99 2.19165 59.99 3.02447V56.9627C59.99 57.7955 59.6513 58.5526 59.1014 59.0985C58.5554 59.6484 57.8023 59.9871 56.9695 59.9871Z"
        fill="#4CBF8C"
      />
      <path
        d="M29.9498 15.166L45.359 25.5066L44.4903 19.5294L29.9498 8.08105L15.6922 19.637L14.8553 25.6142L29.9498 15.166Z"
        fill="white"
      />
      <path
        d="M21.2232 22.1274L14.7319 26.5028L13.7716 33.3447L20.5936 29.7225L21.2232 22.1274Z"
        fill="white"
      />
      <path
        d="M29.9498 24.7296L46.4827 33.2411L45.4865 26.3953L29.9498 16.238V24.7296Z"
        fill="white"
      />
      <path
        d="M47.0167 40.9079C46.1081 40.9079 45.2195 41.135 44.3588 41.2984C43.1395 40.0432 41.7487 38.8996 40.2266 37.9353L39.6049 30.8025L29.9458 26.0247L13.624 34.3649L12.7075 40.9119C7.0252 41.0234 2.16375 44.5978 0 49.5031C2.70169 45.9009 7.12482 43.5498 12.1297 43.5498C13.0821 43.5498 14.0145 43.6375 14.9191 43.8009C18.7365 40.0512 24.0204 37.7241 29.9338 37.7241C35.8512 37.7241 41.2546 40.0552 45.0721 43.8009C45.9766 43.6375 46.9091 43.5498 47.8614 43.5498C52.8624 43.5498 57.2895 45.9009 59.9912 49.5031C57.7955 44.5261 52.8145 40.9079 47.0167 40.9079Z"
        fill="white"
      />
    </svg>
  );
}
