import { SVGProps } from "react";

export default function LocationIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M10.0002 18.685C9.90154 18.686 9.80373 18.6671 9.71264 18.6292C9.62154 18.5914 9.53906 18.5356 9.47016 18.465L4.81016 13.805C3.7836 12.7785 3.08448 11.4706 2.80123 10.0468C2.51798 8.62296 2.66331 7.14711 3.21884 5.80587C3.77437 4.46463 4.71516 3.31825 5.92223 2.5117C7.12929 1.70515 8.54842 1.27466 10.0002 1.27466C11.4519 1.27466 12.871 1.70515 14.0781 2.5117C15.2852 3.31825 16.2259 4.46463 16.7815 5.80587C17.337 7.14711 17.4823 8.62296 17.1991 10.0468C16.9158 11.4706 16.2167 12.7785 15.1902 13.805L10.5252 18.47C10.4563 18.5388 10.3744 18.5933 10.2842 18.6302C10.1941 18.6671 10.0975 18.6857 10.0002 18.685ZM10.0002 2.52999C8.796 2.52909 7.61866 2.88549 6.61715 3.55405C5.61565 4.22262 4.83502 5.1733 4.37409 6.28575C3.91316 7.39819 3.79265 8.62238 4.02781 9.80335C4.26298 10.9843 4.84325 12.069 5.69516 12.92L10.0002 17.225L14.3052 12.92C15.1571 12.069 15.7373 10.9843 15.9725 9.80335C16.2077 8.62238 16.0872 7.39819 15.6262 6.28575C15.1653 5.1733 14.3847 4.22262 13.3832 3.55405C12.3817 2.88549 11.2043 2.52909 10.0002 2.52999Z"
        fill="#232942"
      />
      <path
        d="M9.99914 9.99999C11.1755 9.99999 12.1291 9.04636 12.1291 7.86999C12.1291 6.69362 11.1755 5.73999 9.99914 5.73999C8.82277 5.73999 7.86914 6.69362 7.86914 7.86999C7.86914 9.04636 8.82277 9.99999 9.99914 9.99999Z"
        fill="#232942"
      />
    </svg>
  );
}
