import { SVGProps } from "react";

export default function BankIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.9383 3.09494L13.7924 3.11307C13.7547 3.11498 13.7169 3.11826 13.6793 3.12294L13.6479 3.12685L13.6172 3.1347L13.616 3.135L13.5 3.14941V3.16465L3.37617 5.7527L3 5.84886V6.23712V8.96712V9.46712H3.5H24.5H25V8.96712V6.23712V5.84989L24.6251 5.75302L14.3841 3.10702L14.3528 3.09893L14.3207 3.09494C14.1937 3.07916 14.0653 3.07916 13.9383 3.09494ZM13.9995 2.36912L14.3737 2.36951C14.4426 2.38013 14.511 2.39371 14.5789 2.41023C14.5796 2.41042 14.5804 2.41061 14.5812 2.4108L25.075 5.09024C25.0752 5.09028 25.0753 5.09032 25.0754 5.09036C25.2687 5.14036 25.4399 5.25321 25.562 5.41115C25.6842 5.56921 25.7504 5.76345 25.75 5.96324V5.96412V9.31712C25.75 9.55581 25.6552 9.78473 25.4864 9.95352C25.3176 10.1223 25.0887 10.2171 24.85 10.2171H3.15C2.91131 10.2171 2.68239 10.1223 2.51361 9.95352C2.34482 9.78473 2.25 9.55581 2.25 9.31712L2.25 5.96412L2.25 5.96324C2.24965 5.76345 2.31579 5.56921 2.438 5.41115C2.55998 5.25339 2.7309 5.14062 2.9239 5.09053C2.92427 5.09043 2.92463 5.09034 2.925 5.09024L13.4188 2.4388C13.4194 2.43866 13.42 2.43851 13.4206 2.43837C13.6101 2.39216 13.8044 2.36891 13.9995 2.36912Z"
        fill="#005fc5"
        stroke="#005fc5"
      />
      <path
        d="M24.5 22.6941V24.5001H3.5V22.6941H24.5ZM24.85 20.9441H3.15C2.7787 20.9441 2.4226 21.0916 2.16005 21.3541C1.8975 21.6167 1.75 21.9728 1.75 22.3441V24.8501C1.75 25.2214 1.8975 25.5775 2.16005 25.84C2.4226 26.1026 2.7787 26.2501 3.15 26.2501H24.85C25.2213 26.2501 25.5774 26.1026 25.84 25.84C26.1025 25.5775 26.25 25.2214 26.25 24.8501V22.3441C26.25 21.9728 26.1025 21.6167 25.84 21.3541C25.5774 21.0916 25.2213 20.9441 24.85 20.9441Z"
        fill="#005fc5"
      />
      <path
        d="M7.88182 10.7242V20.9372H6.23682V10.7242H7.88182ZM9.63182 8.97418H4.48682V22.6872H9.63182V8.97418Z"
        fill="#005fc5"
      />
      <path
        d="M21.7628 10.7242V20.9372H20.1178V10.7242H21.7628ZM23.5128 8.97418H18.3678V22.6872H23.5128V8.97418Z"
        fill="#005fc5"
      />
    </svg>
  );
}
