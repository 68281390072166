import { SVGProps } from "react";

function SearchIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.5602 17.6752L13.5852 12.6752C14.7406 11.2919 15.3179 9.51636 15.197 7.71813C15.0761 5.9199 14.2662 4.2376 12.9361 3.02148C11.6059 1.80537 9.85797 1.14917 8.05613 1.1895C6.25429 1.22983 4.53743 1.96359 3.26302 3.238C1.98861 4.51241 1.25485 6.22926 1.21452 8.0311C1.17419 9.83294 1.8304 11.5809 3.04651 12.9111C4.26262 14.2412 5.94492 15.0511 7.74315 15.172C9.54138 15.2929 11.317 14.7156 12.7002 13.5602L17.7002 18.5352C17.7581 18.5926 17.8268 18.6381 17.9023 18.669C17.9778 18.6999 18.0586 18.7156 18.1402 18.7152C18.3063 18.7152 18.4658 18.6506 18.5852 18.5352C18.6897 18.4148 18.7452 18.2596 18.7405 18.1003C18.7359 17.941 18.6715 17.7892 18.5602 17.6752ZM2.5002 8.23018C2.5002 7.09689 2.83626 5.98905 3.46588 5.04676C4.0955 4.10446 4.9904 3.37004 6.03742 2.93634C7.08444 2.50265 8.23656 2.38918 9.34807 2.61027C10.4596 2.83137 11.4806 3.3771 12.2819 4.17845C13.0833 4.97981 13.629 6.0008 13.8501 7.11231C14.0712 8.22382 13.9577 9.37593 13.524 10.423C13.0903 11.47 12.3559 12.3649 11.4136 12.9945C10.4713 13.6241 9.36348 13.9602 8.2302 13.9602C6.71091 13.9589 5.25424 13.3547 4.17994 12.2804C3.10564 11.2061 2.50152 9.74946 2.5002 8.23018Z"
        fill="#4C516D"
        stroke="#4C516D"
        strokeWidth="0.5"
      />
    </svg>
  );
}

export default SearchIcon;
