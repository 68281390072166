import { SVGProps } from "react";

function LogoutIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M7.676 10.7524C7.58211 10.8484 7.53021 10.9778 7.53171 11.112C7.53321 11.2463 7.58799 11.3745 7.684 11.4684C7.78001 11.5623 7.90938 11.6142 8.04366 11.6127C8.17793 11.6112 8.30611 11.5564 8.4 11.4604L11.508 8.35237C11.5999 8.25823 11.6513 8.13191 11.6513 8.00037C11.6513 7.86883 11.5999 7.74251 11.508 7.64837L8.4 4.54037C8.3045 4.45548 8.18013 4.41037 8.05242 4.4143C7.92471 4.41823 7.80334 4.47091 7.71325 4.56151C7.62316 4.65212 7.57117 4.77378 7.56796 4.90151C7.56475 5.02924 7.61057 5.15336 7.696 5.24837L9.944 7.50037H3.776C3.54244 7.50037 3.31116 7.4543 3.09543 7.3648C2.87969 7.2753 2.68373 7.14412 2.51876 6.97878C2.35379 6.81344 2.22306 6.61718 2.13404 6.40124C2.04502 6.18531 1.99947 5.95393 2 5.72037V5.32037C2 5.18776 1.94732 5.06059 1.85355 4.96682C1.75979 4.87305 1.63261 4.82037 1.5 4.82037C1.36739 4.82037 1.24021 4.87305 1.14645 4.96682C1.05268 5.06059 1 5.18776 1 5.32037V5.72037C0.999973 6.45884 1.29168 7.16741 1.8116 7.69183C2.33152 8.21625 3.03756 8.51404 3.776 8.52037H9.924L7.676 10.7524Z"
        fill="#232942"
      />
      <path
        d="M12.4317 1.12793H7.47168C6.80929 1.13004 6.17463 1.39411 5.70624 1.86249C5.23786 2.33088 4.97379 2.96554 4.97168 3.62793V5.05593C4.97168 5.18854 5.02436 5.31571 5.11813 5.40948C5.21189 5.50325 5.33907 5.55593 5.47168 5.55593C5.60429 5.55593 5.73146 5.50325 5.82523 5.40948C5.919 5.31571 5.97168 5.18854 5.97168 5.05593V3.62793C5.97274 3.23043 6.13111 2.84951 6.41219 2.56844C6.69326 2.28736 7.07418 2.12899 7.47168 2.12793H12.4317C12.8292 2.12899 13.2101 2.28736 13.4912 2.56844C13.7722 2.84951 13.9306 3.23043 13.9317 3.62793V12.0839C13.9338 12.2823 13.8966 12.479 13.8221 12.6629C13.7477 12.8467 13.6375 13.014 13.498 13.1549C13.3585 13.2959 13.1925 13.4079 13.0094 13.4842C12.8264 13.5606 12.63 13.5999 12.4317 13.5999H7.47168C7.07385 13.5999 6.69232 13.4419 6.41102 13.1606C6.12971 12.8793 5.97168 12.4978 5.97168 12.0999V10.6559C5.97168 10.5233 5.919 10.3961 5.82523 10.3024C5.73146 10.2086 5.60429 10.1559 5.47168 10.1559C5.33907 10.1559 5.21189 10.2086 5.11813 10.3024C5.02436 10.3961 4.97168 10.5233 4.97168 10.6559V12.0839C4.97274 12.7466 5.23647 13.3819 5.70508 13.8505C6.17369 14.3191 6.80896 14.5829 7.47168 14.5839H12.4317C13.0947 14.5839 13.7306 14.3205 14.1994 13.8517C14.6683 13.3829 14.9317 12.747 14.9317 12.0839V3.62793C14.9306 2.96521 14.6669 2.32994 14.1983 1.86133C13.7297 1.39272 13.0944 1.12899 12.4317 1.12793Z"
        fill="#232942"
      />
    </svg>
  );
}

export default LogoutIcon;
