import { SVGProps } from "react";

export default function UserIconLineal(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        d="M10.0005 2.5C10.5326 2.5 11.0527 2.65777 11.495 2.95335C11.9374 3.24893 12.2822 3.66905 12.4858 4.16058C12.6894 4.65211 12.7427 5.19298 12.6389 5.71479C12.5351 6.2366 12.2789 6.71591 11.9027 7.09212C11.5265 7.46832 11.0472 7.72452 10.5253 7.82831C10.0035 7.93211 9.46266 7.87884 8.97113 7.67524C8.4796 7.47164 8.05948 7.12685 7.7639 6.68448C7.46831 6.24212 7.31055 5.72203 7.31055 5.19C7.31055 4.47657 7.59396 3.79236 8.09843 3.28788C8.60291 2.78341 9.28712 2.5 10.0005 2.5ZM10.0005 1.25C9.22129 1.25 8.45953 1.48108 7.8116 1.91401C7.16367 2.34694 6.65867 2.96229 6.36046 3.68223C6.06225 4.40217 5.98423 5.19437 6.13625 5.95866C6.28828 6.72294 6.66353 7.42498 7.21455 7.976C7.76557 8.52702 8.46761 8.90227 9.23189 9.05429C9.99618 9.20632 10.7884 9.12829 11.5083 8.83009C12.2283 8.53188 12.8436 8.02688 13.2765 7.37895C13.7095 6.73102 13.9405 5.96926 13.9405 5.19C13.9405 4.14505 13.5254 3.14289 12.7866 2.404C12.0477 1.66511 11.0455 1.25 10.0005 1.25Z"
        fill="#232942"
      />
      <path
        d="M5.91046 10.8099C7.0677 11.7468 8.51152 12.258 10.0005 12.258C11.4894 12.258 12.9332 11.7468 14.0905 10.8099C15.0131 11.4135 15.7921 12.2121 16.3727 13.1495C16.9532 14.0868 17.3211 15.14 17.4505 16.2349C15.0562 17.0736 12.5374 17.5013 10.0005 17.4999C7.46357 17.5013 4.94472 17.0736 2.55046 16.2349C2.67982 15.14 3.04773 14.0868 3.62827 13.1495C4.2088 12.2121 4.98783 11.4135 5.91046 10.8099ZM14.0705 9.52494C13.8203 9.52281 13.5775 9.60964 13.3855 9.76994C12.438 10.5678 11.2391 11.0054 10.0005 11.0054C8.76179 11.0054 7.56293 10.5678 6.61546 9.76994C6.4234 9.60964 6.18062 9.52281 5.93046 9.52494C5.71991 9.52322 5.51333 9.58225 5.33546 9.69494C4.19566 10.4114 3.23699 11.3818 2.53441 12.5302C1.83184 13.6786 1.40438 14.9739 1.28546 16.3149C1.26527 16.5342 1.31804 16.7541 1.43557 16.9403C1.5531 17.1266 1.72882 17.2688 1.93546 17.3449C4.52285 18.2741 7.25129 18.7494 10.0005 18.7499C12.7496 18.7494 15.4781 18.2741 18.0655 17.3449C18.2721 17.2688 18.4478 17.1266 18.5654 16.9403C18.6829 16.7541 18.7357 16.5342 18.7155 16.3149C18.5965 14.9739 18.1691 13.6786 17.4665 12.5302C16.7639 11.3818 15.8053 10.4114 14.6655 9.69494C14.4876 9.58225 14.281 9.52322 14.0705 9.52494Z"
        fill="#232942"
      />
    </svg>
  );
}
